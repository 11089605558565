import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CustomMarkdown from "../utils/customMarkdown"
import useSiteMetadata from "../utils/useSiteMetadata"
import { removeSlashFromSlug } from '../utils/additionalFunctions';

import "../scss/legal.scss"
import 'uikit/dist/css/uikit.css'

export const query = graphql`
query legalsQuery($id: Int) {
  strapiLegals(strapiId: {eq: $id}) {
    SEOTitle
    SEODescription
    Canonical
    MetaTags{
      MetaName
      MetaContent
    }
    Title
    Content
    slug
    strapiId
  }

}
`



const Legal = ({data, pageContext}) => {

  
  const { siteUrl: url } = useSiteMetadata();

    return(
    <div>
      <Layout type="privacy">
        <SEO title={data.strapiLegals.SEOTitle?data.strapiLegals.SEOTitle:data.strapiLegals.Title} 
        description={data.strapiLegals.SEODescription} 
        meta={data.strapiLegals.MetaTags}
        canonical={data.strapiLegals.Canonical ? data.strapiLegals.Canonical : `${url + '/' + removeSlashFromSlug(data.strapiLegals.slug)}`}
        />

        <div className="title"><h1>{data.strapiLegals.Title}</h1></div>

        <section className="legal-section">
        
        
            <CustomMarkdown children={data.strapiLegals.Content}></CustomMarkdown>
          

         
          
        </section>


      </Layout>
  
  
      
    </div>
  )
    }
  
  export default Legal